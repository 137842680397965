import { useNavigate, useSearchParams } from "react-router-dom"
import { back_arrow_icon } from "utils/images";

const DefaultComponent = ({ text }: { text: string }) => (
    <p className="user_title">{text}</p>
)

const BackComponent = ({ text, navigateTo }: { text: string, navigateTo: string }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const tab = searchParams.get('tab');

    let nextText: string = text;
    let isRegion = window.location.href.includes('regionId')
    // if(tab){
    //     nextText = 'Regions List'
    // }else{
    //     nextText = 'Partner List'
    // }

    switch (true) {
        case tab ==='Regions': nextText = 'Regions List';
            break;
        case  window.location.href.includes('regionId'): nextText = 'Regions List'
        break;
        default: break;
    }
    // switch (tab) {
    //     case 'Regions': nextText = 'Regions List';
    //         break;
    //     default: break;
    // }

    // switch (tab) {
    //     case 'Regions':
    //         nextText = 'Regions List';
    //         break;
    //     default:
    //         // If tab is not 'Regions', we check if isRegion is true
    //         if (isRegion) {
    //             nextText = 'Regions List';
    //         } else {
    //             nextText = 'Partner List';
    //         }
    //         break;
    // }

    // switch (tab) {
    //     case 'Regions':
    //         nextText = 'Regions List';
    //         break;
    //     default:
    //         // If tab is not 'Regions', we check if isRegion is true
    //         if (isRegion) {
    //             nextText = 'Regions List';
    //         } else {
    //             nextText = 'Partner List';
    //         }
    //         break;
    // }

    // switch (tab) {
    //     case 'Regions':
    //         nextText = 'Regions List';
    //         break;
    //     default:
    //         // If tab is not 'Regions', we check if isRegion is true
    //         if (isRegion) {
    //             nextText = 'Regions List';
    //         } else {
    //             nextText = 'Partner List';
    //         }
    //         break;
    // }

    return (
        <p className="back_btn return_page">
            <span className="back_icon" onClick={() => {
                if (text === 'Matches List') {
                    navigate(-1)
                } else {
                    navigate(navigateTo)
                }
            }}>
                <img src={back_arrow_icon} alt="back_arrow_icon" />
            </span>Back to <span className="back_text"> {nextText}</span>
        </p>
    )
}

export const HeaderBackData = {
    '/admin-users': <DefaultComponent text={'Admin Users'} />,
    '/admin-users/add-admin-user': <BackComponent text='Admin User List' navigateTo='/admin-users' />,
    '/partners': <DefaultComponent text={'Partners & Regions'} />,
    '/partners/partner-detail': <BackComponent text='Partner List' navigateTo='/partners' />,
    '/partners/add-partner-user': <BackComponent text='Partner List' navigateTo='/partners' />,
    '/mentors': <DefaultComponent text='Mentors' />,
    '/add-mentors': <BackComponent text='Mentor List' navigateTo='/mentors' />,
    '/edit-mentors/:id': <BackComponent text='Mentor List' navigateTo='/mentors' />,
    '/mentors-details/:id': <BackComponent text='Mentor List' navigateTo='/mentors' />,
    '/mentees': <DefaultComponent text={'Mentees'} />,
    '/edit-mentees/:id': <BackComponent text='Mentees List' navigateTo='/mentees' />,
    '/mentees/add-mentees': <BackComponent text={'Mentees List'} navigateTo='/mentees' />,
    '/mentees/mentee-details': <BackComponent text={'Mentees List'} navigateTo='/mentees' />,
    '/matches': <DefaultComponent text={'Matches'} />,
    '/matches/potential-match-details': <BackComponent text={'Matches List'} navigateTo='/matches' />,
    '/matches/selected-match-details': <BackComponent text={'Matches List'} navigateTo='/matches' />,
    '/pairs': <DefaultComponent text={'Pairs'} />,
    '/pairs/pair-activity': <BackComponent text={'Pairs List'} navigateTo='/pairs' />,
    '/events': <DefaultComponent text={'Events'} />,
    '/add-events': <BackComponent text={'Events List'} navigateTo='/events' />,
    '/events-detail/:id': <BackComponent text={'Events'} navigateTo='/events' />,
    '/content': <DefaultComponent text={'Content'} />,
    '/content/create-content': <BackComponent text={'Content List'} navigateTo='/content' />,
    '/groups': <DefaultComponent text={'Groups'} />,
    '/add-groups': <BackComponent text={'Groups List'} navigateTo='/groups' />,
    '/groups-detail/:id': <BackComponent text={'Groups List'} navigateTo='/groups' />,
    '/announcement': <DefaultComponent text={'Announcements'} />,
    '/messages': <DefaultComponent text={'Messages'} />,
    '/messages/:id': <BackComponent text={'Messages List'} navigateTo='/messages' />,
    '/reporting': <DefaultComponent text={'Progress on Goals'} />,
}