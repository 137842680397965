import { useEffect, useState, forwardRef, useRef } from 'react';

import { arrow, Arrow_blue, Logout, notification_icon, Remove_Mentees, Settings, unread_notify } from "utils/images";
import { cookieDecryption, generateCustomAvatar } from "utils";
import { useLocation } from 'react-router-dom';
import useGetLoginUsers from 'hooks/query/common/useGetLoginUsers';
import useLogout from 'hooks/query/common/useLogout';
import useHeader from 'hooks/store/common/useHeader';
import useLoginAs from 'hooks/query/common/useLoginAs';

import useRemoveLogin from 'hooks/query/common/useRemoveLogin';
import { HeaderBackData } from './HeaderBackData';
import MenuContainer from 'components/table-container/MenuContainer';
import useAdminUsers from 'hooks/store/admin-users/useAdminUsers';
import SettingsPopup from 'modal/admin-users/Settings';
import Loader from 'components/loader/Loader';
import Notification from 'components/notification-popup/Notification';
import useSocket from 'hooks/query/messages/useSocket';
import CustomImage from 'components/UI/image/CustomImage';
import useSocketStore from 'hooks/store/messages/useSocketStore';
import { ROLE } from "static";
import useToaster from 'hooks/store/toast/useToaster';


type HeaderProps = {}

function Header({ }: HeaderProps) {


    const location = useLocation();
    const pathname = location.pathname;
    const socketData = useSocketStore();
    const { list, removeAllToast } = useToaster()

    const { settingPopup, setState, addClass } = useAdminUsers();
    const { sendEvent } = useSocket();
    const [notificationPopup, setNotificationPopup] = useState<any>(false);
    const { logoutUser, isLoading: logOutLoader } = useLogout();
    const { loginAsUser } = useLoginAs();
    const userProfilePic = sessionStorage.getItem('userProfilePic');
    const { removeUser, data: removeUserResponse, isLoading: removeUserLoader } = useRemoveLogin();
    const { data: loginUsers, getLoginUsers, isLoading: loginUsersLoader } = useGetLoginUsers();
    const { isAddAnotherAccount, loginAsResponse, setIsAddAnotherAccount} = useHeader();
    const [isSeeMoreAccount, setIsSeeMoreAccounts] = useState(false);
    const [next, setNext] = useState(3);

    const users = loginUsers?.account?.slice(0, next);
    const user = cookieDecryption('user');
    const masterUser = cookieDecryption('masterUser');

    const { I_SUPER_ADMIN } = ROLE;
    const handleLogOut = () => {
        logoutUser()
        socketData.socket?.emit(
            "leave", { data: { user_id: user.userId } }
        );
        socketData.socket?.close();
    }


    const isLoading = logOutLoader || removeUserLoader || loginUsersLoader;

    useEffect(() => {
        getLoginUsers({ userId: masterUser?.userId, uniqueId: masterUser?.uniqueId });
        isAddAnotherAccount && setIsAddAnotherAccount();

        return () => {
            setIsSeeMoreAccounts(false);
            setNext(3);
        }
    }, [removeUserResponse, loginAsResponse])

    const handleLessOrMore = (records: number, flag: boolean) => {
        setNext(records);
        setIsSeeMoreAccounts(flag);
    };

    const HeaderLeftComponent = (): JSX.Element | undefined => {
        for (const [key, Component] of Object.entries(HeaderBackData)) {
            switch (true) {
                case (key === pathname): return Component

                case (key === `/${pathname?.split("/")[1]}/:id`): return Component

                default: break;
            }

        }
    }

    const notificationPopupHandler = () => {
        setNotificationPopup(!notificationPopup)
        const payload = {
            event: "getNotifications",
            data: { page: 1, limit: 10 },
        };
        sendEvent({ payload });
    }
    const handleBellIconClick = () => {
        const payload = {
            event: "readNotification",
            data: {},
        };
    }

    const notificationContinerHandler = () => {
        const TitleComponent = forwardRef(({ onTitleClickHandler, isOpen }: { onTitleClickHandler(event: any): void, isOpen: boolean }, inputRef: any): JSX.Element => (
            <div className='d_flex_center' onClick={(event) => {
                onTitleClickHandler(event);
                notificationPopupHandler();
                event.stopPropagation()
            }} ref={inputRef}>
                {
                    socketData?.getNotificationCount > 0 && <div className='notification_dot'>
                        <span className='d_flex'><CustomImage src={unread_notify} /></span>
                    </div>
                }
                <span className={`flex flex-center flex-just-center shape circle-shape cursor_pointer`} onClick={handleBellIconClick}>
                    <CustomImage src={notification_icon} />
                </span>
            </div>

        ))
        const ChildComponent = ({ isOpen, setIsOpen }: { isOpen: boolean, setIsOpen(flag: boolean): void }): JSX.Element => (
            <div style={{ opacity: isOpen ? '1' : '0' }}>
                <Notification isOpen={isOpen} />
            </div>
        )
        return <MenuContainer className={''} TitleComponent={TitleComponent} ChildComponent={ChildComponent} transitionClass="fade" />
    }
    const menuConatinerHandler = () => {

        const TitleComponent = forwardRef(({ onTitleClickHandler, isOpen }: { onTitleClickHandler(): void, isOpen: boolean }, inputRef: any): JSX.Element => {
            const userName = user?.fullName.split(/\s+/);

            return (
                <a className={`user_profile ${isOpen && 'active'}`} onClick={onTitleClickHandler} ref={inputRef}>
                    {userName &&
                        <img
                            src={userProfilePic ?  userProfilePic : generateCustomAvatar(user?.fullName, 'small-caps bold 60px Montserrat_ragular')}
                            alt="profile-pic"
                            className="user_profile_pic"
                            style={{ borderRadius: '50%', objectFit: 'cover' }}
                        />
                    }

                    <div className="user-text">
                        <p>{user?.fullName}</p>
                        <span>{user?.role === I_SUPER_ADMIN ? user?.role : (user?.region ? user?.region : user?.partnerName)}
                        </span>
                    </div>
                </a>
            )
        }
        );

        const ChildComponent = ({ isOpen, setIsOpen }: { isOpen: boolean, setIsOpen(flag: boolean): void }): JSX.Element => (
            <div className='login_as_popup'>

                {users?.length !== 0 && <div className='inner_user'>
                    <div className='top_header d_flex_between add_back_btn'>
                        <div className='select_btn'>
                            <button className='d_flex_center'>
                                {isSeeMoreAccount && <img src={arrow} alt="arrow" className='back_btn_img' onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                    event.stopPropagation();
                                    handleLessOrMore(3, false);
                                }} />}
                                <p className='title_name'>{isSeeMoreAccount ? 'Select Account' : loginUsers?.account?.length !== 0 ? 'Login as' : ''}</p>
                            </button>
                        </div>
                        <p className='title_name d_none'>Login as</p>
                    </div>
                    <div className='all_account_data'>
                        <div className='inner_all_user'>
                            {
                                users?.map(({ fullName, role, userId, region, profilePic }: any) => {
                                    return (
                                        <div className='sub_account d_flex' onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                            event.stopPropagation();
                                            setIsOpen(false);
                                            loginAsUser({ userId: userId, masterUserId: masterUser?.userId }, true)
                                            removeAllToast({ list: [] })
                                        }
                                        } >
                                            <div className='user_profile'>
                                                <img
                                                    src={profilePic}
                                                    alt="profile-pic"
                                                    style={{ borderRadius: '50%', objectFit: 'cover', width: '4rem', height: '4rem' }}
                                                />
                                            </div>
                                            <div className='u_name'>
                                                <h6>{fullName}</h6>
                                                <p>{role}{region && <span>{`, ${region}`}</span>}</p>

                                            </div>
                                            <div className='remove_user'>
                                                {(role !== masterUser?.role || user?.role === I_SUPER_ADMIN) && <img src={Remove_Mentees} alt="remove" className="remove_icon" onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                                    event.stopPropagation();
                                                    removeUser({ userId: userId, masterUserId: masterUser?.userId });
                                                }} />}
                                                <div className="tooltip">Remove from list</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            loginUsers?.account?.length > 3 && !isSeeMoreAccount && <div className='sub_account d_flex_between' onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                event.stopPropagation();
                                handleLessOrMore(loginUsers?.account?.length, true)
                            }
                            }>
                                <button className='btn assign_btn'>See more accounts <img src={Arrow_blue} alt=''></img></button>
                                <p className='total_count'>{loginUsers?.account?.length - 3}</p>
                            </div>}
                    </div>
                </div>}

                {
                    !isSeeMoreAccount &&
                    <div className='inner_user more_option_sec'>
                        {users?.length !== 0 && <div className='top_header d_flex_between'>
                            <p className='title_name'>More options</p>
                        </div>}
                        <div className='all_account_data'>
                            <div className='sub_account d_flex align_items_center' onClick={() => setState({ settingPopup: !settingPopup })}>
                                <img src={Settings} alt="" />
                                <div className='u_name'>
                                    <h6>Settings</h6>
                                </div>
                            </div>
                            <div className='sub_account d_flex align_items_center' onClick={() => handleLogOut()}>
                                <img src={Logout} alt="" />
                                <div className='u_name'>
                                    <h6>Log out</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )

        return <MenuContainer className={'dot_icon_modal'} TitleComponent={TitleComponent} ChildComponent={ChildComponent} transitionClass="fade" />
    }
    const geoSuggestList: any = useRef(null);

    const handleOutsideClick = (e: any) => {
        if (
            geoSuggestList?.current &&
            !geoSuggestList?.current?.contains(e.target)
        ) {

            const geoSuggestList: any = document.getElementById('geosuggest__list');
            setNotificationPopup(false)
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [notificationPopup]);
    return (
        <>
            <header className={`flex flex-center flex-between border-bottom ${(pathname === '/add-mentors' || pathname?.includes('edit-mentors') || pathname?.includes('edit-Index') || pathname === '/mentees/add-mentees' || pathname === '/add-events' || pathname?.includes('/messages') || pathname === '/add-groups' || pathname?.includes('edit-mentees')) && 'header_sticky'} `}>
                <div className="header-left">
                    <div className='cms_hamburger' onClick={() => setState({ addClass: !addClass })}>
                        <div className='menu_icon'>
                            <span className='menu_line'></span>
                        </div>
                    </div>
                    {HeaderLeftComponent()}
                </div>
                <ul className="header-right d_flex_align">
                    <li className='d_none'>
                        <a href="#" className="flex flex-center flex-just-center shape">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84375 1.99977C7.11984 1.99977 5.46654 2.68458 4.24756 3.90357C3.02857 5.12256 2.34375 6.77586 2.34375 8.49977C2.34375 10.2237 3.02857 11.877 4.24756 13.096C5.46654 14.3149 7.11984 14.9998 8.84375 14.9998C10.5677 14.9998 12.221 14.3149 13.4399 13.096C14.6589 11.877 15.3438 10.2237 15.3438 8.49977C15.3438 6.77586 14.6589 5.12256 13.4399 3.90357C12.221 2.68458 10.5677 1.99977 8.84375 1.99977ZM0.34375 8.49977C0.343865 7.14436 0.668114 5.80863 1.28944 4.60402C1.91077 3.39941 2.81117 2.36086 3.9155 1.575C5.01984 0.789144 6.2961 0.278775 7.63779 0.0864735C8.97949 -0.105828 10.3477 0.0255146 11.6283 0.469544C12.9089 0.913572 14.0648 1.65741 14.9995 2.639C15.9341 3.62059 16.6205 4.81147 17.0014 6.11228C17.3822 7.41309 17.4464 8.78611 17.1887 10.1168C16.9309 11.4475 16.3587 12.6972 15.5198 13.7618L19.1717 17.4138C19.3539 17.6024 19.4547 17.855 19.4524 18.1172C19.4501 18.3794 19.345 18.6302 19.1596 18.8156C18.9742 19.001 18.7233 19.1062 18.4612 19.1084C18.199 19.1107 17.9464 19.0099 17.7577 18.8278L14.1058 15.1758C12.8524 16.1638 11.3462 16.7789 9.75948 16.9509C8.1728 17.1228 6.56977 16.8446 5.13384 16.148C3.69792 15.4514 2.48711 14.3646 1.63998 13.0121C0.792856 11.6595 0.343643 10.0957 0.34375 8.49977ZM7.84375 4.99977C7.84375 4.73455 7.94911 4.4802 8.13664 4.29266C8.32418 4.10512 8.57853 3.99977 8.84375 3.99977C10.0372 3.99977 11.1818 4.47387 12.0257 5.31778C12.8696 6.1617 13.3438 7.30629 13.3438 8.49977C13.3438 8.76498 13.2384 9.01934 13.0509 9.20687C12.8633 9.39441 12.609 9.49977 12.3438 9.49977C12.0785 9.49977 11.8242 9.39441 11.6366 9.20687C11.4491 9.01934 11.3438 8.76498 11.3438 8.49977C11.3438 7.83672 11.0804 7.20084 10.6115 6.732C10.1427 6.26316 9.50679 5.99977 8.84375 5.99977C8.57853 5.99977 8.32418 5.89441 8.13664 5.70687C7.94911 5.51934 7.84375 5.26498 7.84375 4.99977Z" fill="#1E2D66" />
                            </svg>
                        </a>
                    </li>
                    <li className='position_relative' onClick={notificationPopupHandler} ref={geoSuggestList}>
                        {socketData?.getNotificationCount > 0 && <div className='notification_dot'>
                            <span><CustomImage src={unread_notify} /></span>
                        </div>}

                        <span className={`flex flex-center flex-just-center shape circle-shape cursor_pointer`} onClick={handleBellIconClick}>
                            <CustomImage src={notification_icon} />
                        </span>

                        {notificationPopup && <Notification />}
                    </li>
                    <li className="user-info active">

                        {menuConatinerHandler()}

                    </li>
                </ul>
            </header>
            {settingPopup && <SettingsPopup />}
            {isLoading && <Loader />}
        </>
    )
}

export default Header