import { lazy } from "react";
import { ROLE } from "static/index";

const Login = lazy(() => import("views/login/Login"));
const ForgotPassword = lazy(
  () => import("views/forgot-password/ForgotPassword")
);
const ChangePassword = lazy(
  () => import("views/change-password/ChangePassword")
);
const Register = lazy(() => import("views/register/Register"));
const LoginAfterPermission = lazy(() => import("views/login/LoginAfterPermission"));

const AdminUsers = lazy(() => import("views/admin-users/AdminUsers"));
const AddAdminUser = lazy(() => import("views/add-admin-user/AddAdminUser"));
const Partners = lazy(() => import("views/partners/Partners"));
const PartnerDetails = lazy(() => import("views/partners/PartnerDetails"));
const AddPartnerUser = lazy(
  () => import("views/add-partner-user/AddPartnerUser")
);
const Mentors = lazy(() => import("views/Mentors"));
const AddMentees = lazy(() => import("views/Mentees/AddMentees"));
const EditMentees = lazy(() => import("views/Mentees/UpdateMentees"));
const MenteeDetails = lazy(() => import("views/Mentees/MenteeDetails/MenteeDetails"));
const AddMentors = lazy(() => import("../../views/Mentors/AddMentor"));
const SelectedMatchDetails = lazy(() => import("views/Matches/PairsMatches/SelectedMatchDetails"));
const PotentialMatchDetails = lazy(() => import("views/Matches/PotentialMatches/PotentialMatchesDetails"));
const MentorDetails = lazy(() => import("views/mentor-details/MentorDetails"));
const Mentees = lazy(() => import("views/Mentees"));
const Matches = lazy(() => import("views/Matches"));
const Annoucements = lazy(() => import("views/Annoucements"));
const UnAuthorized = lazy(() => import("views/unauthorized/UnAuthorized"));
const EditMentors = lazy(() => import("../../views/Mentors/EditMentor"));
// const EditMentors = lazy(() => import("views/edit-Index/EditMentors"));
const Pairs = lazy(() => import("views/Pairs"));
const PairActivity = lazy(() => import("views/Pairs/pairActivity/PairActivity"));
const Groups = lazy(() => import("views/groups/Groups"));
const AddGroup = lazy(() => import("views/add-group/AddGroup"));
const GroupDetail = lazy(() => import("views/group-detail/GroupDetail"));
const Activities = lazy(() => import("views/activities/Activities"));
const Messages = lazy(() => import("views/Messages"));
const SwitchAccount = lazy(() => import("views/switch-account/SwitchAccount"));

const Content = lazy(() => import("views/content/Content"));
const CreateContent = lazy(() => import("views/content/CreateContent"));
const Events = lazy(() => import("views/events/Events"));
const EventsDetail = lazy(() => import("views/events/EventDetail"));
const AddEvents = lazy(() => import("views/add-events/AddEvents"));
const Reporting = lazy(() => import("views/Reporting"));

const { I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN } = ROLE;

export const privateRouteList = [
  {
    title: "admin-users",
    path: "/admin-users",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN],
    element: AdminUsers,
  },
  {
    title: "add-admin-user",
    path: "admin-users/add-admin-user",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN],
    element: AddAdminUser,
  },
  {
    title: "partners",
    path: "/partners",
    allowedRoles: [I_SUPER_ADMIN],
    element: Partners,
  },
  {
    title: "add-partner-user",
    path: "partners/add-partner-user",
    allowedRoles: [I_SUPER_ADMIN],
    element: AddPartnerUser,
  },
  {
    title: "partner-detail",
    path: "partners/partner-detail",
    allowedRoles: [I_SUPER_ADMIN],
    element: PartnerDetails,
  },
  {
    title: "mentors",
    path: "/mentors",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Mentors,
  },
  {
    title: "Add Mentor",
    path: "/add-mentors",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: AddMentors,
  },
  // {
  //     title: 'Edit Profile Popup',
  //     path: '/edit-profile-popup',
  //     allowedRoles: [I_SUPER_ADMIN],
  //     element: EditProfilePopups
  // },
  {
    title: "Reporting",
    path: "/reporting",
    allowedRoles: [I_SUPER_ADMIN,P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Reporting,
  },
  {
    title: "selected Match Details",
    path: "Matches/selected-match-details",
    allowedRoles: [P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: SelectedMatchDetails,
  },
  {
    title: "potential match Details",
    path: "Matches/potential-match-details",
    allowedRoles: [I_SUPER_ADMIN,P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: PotentialMatchDetails,
  },
  {
    title: "mentor Details",
    path: "/mentors-details/:id",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: MentorDetails,
  },
  {
    title: "mentees",
    path: "/mentees",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Mentees,
  },
  {
    title: "Add Mentees",
    path: "/mentees/add-mentees",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: AddMentees,
  },
  {
    title: "Mentee Details",
    path: "/mentees/mentee-details",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: MenteeDetails,
  },
  {
    title: "matches",
    path: "/matches",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Matches,
  },
  {
    title: "Edit Mentor",
    path: "/edit-mentors/:id",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: EditMentors,
  },
  {
    title: "Pairs",
    path: "/pairs",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Pairs,
  },
  {
    title: "Pair Activity",
    path: "pairs/pair-activity",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: PairActivity,
  },
  {
    title: "groups",
    path: "/groups",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Groups,
  },
  {
    title: "add-groups",
    path: "/add-groups",
    allowedRoles: [P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: AddGroup,
  },
  {
    title: "groups-detail",
    path: "/groups-detail/:groupId",
    allowedRoles: [P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: GroupDetail,
  },
  {
    title: "unauthorized",
    path: "/unauthorized",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: UnAuthorized,
  },
  {
    title: "Edit Mentees",
    path: "/edit-mentees/:id",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: EditMentees,
  },
  {
    title: "content",
    path: "/content",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Content,
  },
  {
    title: "content",
    path: "/content/create-content",
    allowedRoles: [P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: CreateContent,
  },
  {
    title: "Annoucements",
    path: "/announcement",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Annoucements,
  },
  {
    title: "activities",
    path: "/activities",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Activities,
  },
  {
    title: "messages",
    path: "/messages",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Messages,
  },
  {
    path: "/messages/:userId",
    element: Messages,
    title: "Messages",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
  },
  {
    title: "events",
    path: "/events",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: Events,
  },
  {
    title: "events",
    path: "/events-detail/:id",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: EventsDetail,
  },
  {
    title: "switch-account",
    path: "/switch-account",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: SwitchAccount,
    isHideLayout: true
  },
  {
    title: "Add Events",
    path: "/add-events",
    allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
    element: AddEvents,
  },
  // {
  //   title: "Add Matching Questions",
  //   path: "/add-matching-questions",
  //   element: AddMatchingQuestions,
  //   allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
  // },
  // {
  //   title: "Edit Matching Questions",
  //   path: "/edit-matching-questions/:id",
  //   element: AddMatchingQuestions,
  //   allowedRoles: [I_SUPER_ADMIN, P_SUPER_ADMIN, I_LOCAL_ADMIN, P_LOCAL_ADMIN],
  // },
];

export const publicRouteList = [
  {
    title: "login",
    path: "/",
    element: Login,
  },
  {
    title: "forgot-password",
    path: "/forgot-password",
    element: ForgotPassword,
  },
  {
    title: "change-password",
    path: "/change-password/:params",
    element: ChangePassword,
  },
  {
    title: "register",
    path: "/register",
    element: Register,
  },
  {
    title: "login",
    path: "/login",
    element: LoginAfterPermission,
  },
];
