export const rules = {
    email: {
        required: true,
        validate: { matchPattern: (value: string) => /^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i.test(value.trim()) },
    },
    contactEmail: {
        required: { value: true, message: 'This field is required' },
        pattern: { value: /^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/ , message: 'Please enter email format!' },
    },
    password: {
        required: true,
        validate: { matchPattern: (value: string) => /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(value.trim()) },
    },
    name: {
        required: true,
        // /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/i
        validate: { matchPattern: (value: string) => /[\u0000-\u10FFFF]/gu.test(value.trim()) },
    },
    fullName: {
        required: { value: true, message: 'This field is required' },
        ///^([A-Za-z0-9\-.']+\s)*[A-Za-z0-9\-.']+(?:\s+[A-Za-z0-9\-.']+)*(?:\s+[A-Za-z0-9\-.']+\s+[A-Za-z0-9\-.']+)*$/
        ///^[A-Za-z0-9.' À-ÖØ-öø-ÿ -]+$/
        pattern: { value: /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~\u00C0-\u017F]+$/, message: 'Please enter valid name format!' },
    },
    zip: {
        required: true,
        validate: { matchPattern: (value: string) => /^\d{5}$/.test(value.trim()) }
    }
}

