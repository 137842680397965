import socketIo from "socket.io-client";
import { Encryption, cookieDecryption } from "../../../utils";
import useSocketStore from "../../store/messages/useSocketStore";
import useLoginStore from "hooks/store/common/useLoginStore";

const requestHandler = (event: string, data: any) => {
  switch (event) {
    case "join": {
      return data;
    }
    default: {
      return { data, event };
    }
  }
};

export default function useSocket() {
  const socketData = useSocketStore();
  const {setState:setSessionExpire} = useLoginStore()
  const { setState } = socketData;

  async function getSocket({ token, userData }: any) {
    const socket: any = socketIo(process.env.REACT_APP_SOCKET_POINT, {
      transports: ["websocket"],
    });

    setState({
      ...socketData.getState(),
      socket,
    });

    socket.on("connect", () => {
      const payload = {
        event: "join",
        data: {
          user_id: userData?.userId,
          token: token,
        },
      };

      // socket.emit(
      //   "join",
      //   process.env.REACT_APP_DISABLE_ENCRYPTION === "true"
      //     ? requestHandler("join", payload.data)
      //     : Encryption(requestHandler("join", payload.data))
      // );
      sendEvent({ payload });
    });
  }

  async function sendEvent({ payload, changeLoader }: any) {
    const { event, data } = payload;

    const { socket } = socketData.getState();
    if (!changeLoader ) {
      setState({
        ...socketData.getState(),
        socketLoaders: {
          ...socketData?.getState()?.socketLoaders,
          [event]: true,
        },
      });
    } else {
      setState({
        ...socketData.getState(),
        socketLoaders: {
          ...socketData?.getState()?.socketLoaders,
          [changeLoader]: true,
        },
      });
    }

    if (!socket?.connected) {
      return;
    }
    socket.emit(
      event === "join" ? "join" : "request",
      process.env.REACT_APP_DISABLE_ENCRYPTION === "true"
        ? requestHandler(event, data)
        : Encryption(requestHandler(event, data))
    );
  }

  async function socketResponse({ response }: any) {
      setState({
        ...socketData.getState(),
        socketLoaders: {
          ...socketData?.getState()?.socketLoaders,
          [response?.event]: false,
          chatLoader: false,
        },
      });

    switch (response?.event) {
      case "join": {
        const payload = {
          event: "getCounts",
          data: {},
        };

        setState({
          ...socketData.getState(),
          socketConnected: true,
        });

        await sendEvent({ payload });
        break;
      }
      case "getMsgList": {
        const userId = socketData.chatId
          ? socketData.chatId
          : window.location.pathname.split("messages/")?.[1];
        const user = cookieDecryption("user");
        setState({
          ...socketData.getState(),
          connectionList: response?.data,
        });
        let temp = response?.data?.find(
          (_: any) =>
            _?.lastMessage?.senderId !== user?._id && !_?.lastMessage?.read
        );
        const payload = {
          event: "getMsg",
          data: {
            senderId: userId,
            limit: temp?.unRead > 10 ? temp?.unRead : 10,
          },
        };
        const isMsg =
          typeof temp?.lastMessage?.senderId === "string"
            ? temp?.lastMessage?.senderId
            : temp?.lastMessage?.senderId?._id;
        let conList = response?.data;
        if (isMsg === userId) {
          const payload = {
            event: "readMsg",
            data: { chId: temp?.chId, senderId: isMsg },
          };
          sendEvent({ payload });
          conList = conList?.map((e: any) =>
            e?.users?._id === userId ? { ...e, unRead: 0 } : { ...e }
          );
        }
        if (userId) {
          sendEvent({ payload });
        }
        break;
      }
      case "getMsg": {
        const userId = socketData.chatId
          ? socketData.chatId
          : window.location.pathname.split("messages/")?.[1];
        const user = cookieDecryption("user");

        let temp = response?.data?.conversationlist?.data?.find(
          (_: any) => _?.senderId !== user?._id
        );
        const isMsg =
          typeof temp?.senderId === "string"
            ? temp?.senderId
            : temp?.senderId?._id;
        if (isMsg === userId) {
          const payload = {
            event: "readMsg",
            data: { chId: temp?.chId, senderId: isMsg },
          };
          sendEvent({ payload });
        }
        const conversationData =
          response?.data?.conversationlist?.data?.reverse() ?? [];
        const preMessageList = socketData.getState().messageList?.messageData ?? [];
        const nextMessageList = [...conversationData, ...preMessageList];

        const netMessageObj = {
          ...response?.data?.conversationlist,
          messageData: nextMessageList,
        };

        setState({
          ...socketData.getState(),
          messageList: { ...netMessageObj },
        });

        break;
      }
      case "sendMsg": {
        const userId = socketData.chatId
          ? socketData.chatId
          : window.location.pathname.split("messages/")?.[1];
          
        const user = cookieDecryption("user");
        let lastData = socketData?.getState();
        const newMessage = response.data.conversationlist;

        let temp = [...lastData.connectionList];
        let index = temp.findIndex((data) => data.chId === newMessage.chId);
        if (index > -1) {
          let latestChat = {
            ...temp[index],
            lastMessage: newMessage,
            // unRead: temp[index]?.unRead + 1,
            unRead: userId !== newMessage?.receiverId?._id ? temp[index]?.unRead + 1: 0 ,
          };
          temp.splice(index, 1);
          temp.unshift(latestChat);
        } else {
          temp.unshift({
            ...newMessage,
            receiverId: newMessage.receiverId._id,
            users:
              newMessage.receiverId?._id === user.userId
                ? newMessage.senderId
                : newMessage.receiverId,
            lastMessage: newMessage,
            unRead: newMessage?.lastMessage?.read ? 0 : 1,
          });
        }
        let latest = {
          ...lastData,
          connectionList: temp,
        };

        if (userId === newMessage.senderId._id) {
          const payload = {
            event: "readMsg",
            data: { chId: newMessage?.chId, senderId: userId },
          };
          sendEvent({ payload });
          const msgState = lastData.messageList || {};
          const updatedData = {
            ...msgState,
            messageData: [...(msgState?.messageData || []), newMessage],
            sendMessage: true,
          };
          latest = {
            ...latest,
            messageList: updatedData,
          };
          let nextUsers = [...latest.connectionList];
          let nextUserIndex = nextUsers.findIndex(
            (data) => data.chId === newMessage.chId
          );
          nextUsers[nextUserIndex] = { ...nextUsers[nextUserIndex], unRead: 0 };
          // socketData.setState({ connectionList:nextUsers })
          setState({
            ...latest,
            connectionList: nextUsers,
          });
          return;
        }
        const msgState = lastData.messageList || {};
        const updatedData = {
          ...msgState,
          messageData: [...(msgState?.messageData || []), newMessage],
          sendMessage: true,
        };
        latest = {
          ...latest,
          messageList: updatedData,
        };

        setState({
          ...latest,
        });

        break;
      }
      case "getNotifications": {
        
        setState({ notificationResponse: response.data })
        break;
      }
      case "sendMsgReaction": {
        let lastData = socketData?.getState();
        let data = response?.data;
        const messageList = lastData?.messageList || {};

        let index = messageList?.messageData?.findIndex(
          (_: any) => _._id === data?.messageId
        );
        let newReactions =
          messageList?.messageData?.[index]?.reactions[0] === data.reaction
            ? []
            : [data.reaction];

        let updatedData = {
          ...messageList,
          messageData: [
            ...messageList?.messageData?.slice(0, index),
            {
              ...messageList?.messageData[index],
              reactions: newReactions,
            },
            ...messageList?.messageData?.slice(index + 1),
          ],
          sendReaction: true,
        };
        const newData = socketData?.getState().connectionList?.map((e:any) => (
          e?.lastMessage?.chId === response?.lastMessage?.chId ? { ...e, lastMessage: {...response?.lastMessage} } : { ...e }
        ))
        setState({
          ...lastData,
          messageList: updatedData,
          connectionList:newData
        })
        // dispatch.messagesModel.setMsgState({
        //   name: "connectionList",
        //   data: state.messagesModel.connectionList?.map((e) => e?.lastMessage?.chId === response?.lastMessage?.chId ? { ...e, lastMessage: {...response?.lastMessage, isReacted: true} } : { ...e } ),
        // });

        break;
      }
      case "getCounts": {
        setState({
          getNotificationCount: response?.data?.notificationCount
        })
        break;
      }
      case "readMsg": {
        let lastData = socketData?.getState();
        let nextResponse = { ...lastData };
        const array = nextResponse?.messageList?.messageData ?? [];
        let nextMessageListArray = [...array].map((message: any) => ({
          ...message,
          read: true,
        }));
        nextResponse = { ...nextResponse, messageData: nextMessageListArray };
        let newConnectionList = lastData?.connectionList?.map((e: any) =>
          e?.chId === response.data.chId
            ? { ...e, lastMessage: { ...e?.lastMessage, read: true } }
            : { ...e }
        );

        setState({
          messageList: nextResponse,
          connectionList: newConnectionList,
        });
        return;
      }
      case "readNotification": {

        let nextNotificationResponse = socketData.getState()?.notificationResponse;
        const nextNotificationArray = nextNotificationResponse?.data?.map((notification: any) => ({ ...notification,read:true}));
        nextNotificationResponse = {...nextNotificationResponse , data : nextNotificationArray }
        
        setState({notificationResponse : nextNotificationResponse})

        break;
      }
      case 'error' : {
        setSessionExpire({isSessionExpired:true})
        break
      }
    }
  }

  return {
    getSocket,
    sendEvent,
    socketResponse,
  };
}
