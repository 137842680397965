import { useEffect } from 'react';
import useSettings from 'hooks/query/common/useSettings';
import useAdminUsers from 'hooks/store/admin-users/useAdminUsers'
import { useState } from 'react';
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import ReactInputMask from 'react-input-mask';
import {
    close_icon,
    gridicons_not_visible, gridicons_visible,
    input_fild_remove,
    lock_info,
    personal_pic,
    setting_drop_arrow
} from 'utils/images'
import { rules } from 'utils/rules';
import useUpdateProfile from 'hooks/query/common/useUpdateProfile';
import { cookieDecryption } from 'utils';
import useUpdatePassword from 'hooks/query/common/useUpdatePassword';

interface SettingsProps {

}

interface FormData {
    firstName: string,
    lastName: string,
    email: string,
    number: string,
};

interface ChangePasswordFormData {
    currentPassword: string;
    newPassword: string;
};

export default function Settings({ }: SettingsProps) {
    const [statePassword, setStatePassword]: any = useState({
        isPasswordVisible: true,
        isConfirmPasswordVisible: true
    });
    const [view, setView] = useState('Personal Details');

    const user = cookieDecryption('user');

    const { data } = useSettings();

    const { updateUserProfile } = useUpdateProfile();

    const { response, changePassword } = useUpdatePassword();

    const { register, setValue, handleSubmit, control, watch, formState: { isSubmitted, errors } } = useForm<FormData>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            number: ''
        }
    });

    const { register: changePasswordRegister,
        setValue: setChangePassword,
        handleSubmit: changePasswordhandleSubmit,
        control: changePasswordConrol,
        watch: changePasswordWatch,
        getFieldState,
        resetField,
        formState: { isSubmitted: changePasswordIsSubmitted, errors: changePasswordErrors } } = useForm<ChangePasswordFormData>({
            defaultValues: {
                currentPassword: '',
                newPassword: ''
            }
        });
        
    const currentPasswordData = getFieldState("currentPassword");
    const newPasswordData = getFieldState("newPassword");

    const { settingPopup, setState, passwordError } = useAdminUsers();

    const firstName = watch('firstName');
    const lastName = watch('lastName');
    const email = watch('email');
    const number = watch('number');

    const currentPassword = changePasswordWatch('currentPassword');
    const newPassword = changePasswordWatch('newPassword');

    useEffect(() => {
        if (data && !data.error) {
            const { email, fname, lname, phoneNo } = data;

            setValue('firstName', fname);
            setValue('lastName', lname);
            setValue('email', email);
            setValue('number', phoneNo);

        }
    }, [data])

    const onSubmitPersonalDetails: SubmitHandler<FormData> = data => {
        const { firstName, lastName, email, number } = data;
        const payload = {
            fname: firstName,
            lname: lastName,
            email,
            phoneNo: number
        }
        updateUserProfile(payload);
    };

    const onSubmitChangePassword: any = (data: any) => {

        const payload = {
            userId: user?.userId ?? '',
            ...data
        }
        setState({ passwordError: '' })
        changePassword(payload);
    };


    return (
        <div className='modal_comman'>
            <div className='delete_modal setting_modal active'>
                <div className="close_btn d_flex_center" onClick={() => setState({ settingPopup: !settingPopup })}><img src={close_icon} alt="close_btn" className='w_100' /></div>
                <h2>Settings</h2>
                <div className='setting_list'>
                    <ul>
                        <li>
                            <form className='personal_setting active' id="personal details" onSubmit={handleSubmit(onSubmitPersonalDetails)}>
                                <div className='per_info'>
                                    <div className='per_detials'>
                                        <span className='per_pic'><img src={personal_pic} alt="personal_pic" /></span>
                                        <span className='per_name'>Personal Details</span>
                                    </div>
                                    <div className='per_arrow' onClick={(event) => {
                                        event.preventDefault();
                                        setView('Personal Details');
                                    }}>
                                        <span className={`${view === 'Personal Details' ? 'set_arrow open' : 'set_arrow close'}`}>
                                            <img src={setting_drop_arrow} alt="setting_drop_arrow" />
                                        </span>
                                    </div>
                                </div>
                                {view === 'Personal Details' && <div className='persnol_details'>
                                    <div className='d_flex_center per_detais'>

                                        <div className="form_group">
                                            <label>First Name</label>
                                            <input id="fname" type="text" className={`form_control ${firstName && 'blue_border'}`} {...register("firstName", rules.fullName)} autoComplete='off' placeholder='Enter first name' />
                                            {firstName && <span className='input_fild_remove' onClick={() => setValue('firstName', '')}><img src={input_fild_remove} alt="fild_remove_icon" /></span>}
                                            {errors.firstName?.type === "required" && <span className='show_error_msg'>Please enter first name</span>}
                                            {errors.firstName?.type === "pattern" && <span className='show_error_msg'>Please enter correct first name format!</span>}
                                            {/* <span className="input_fild_remove"><img src={input_fild_remove} alt="fild_remove_icon" /></span> */}
                                        </div>

                                        <div className="form_group">
                                            <label>Last Name</label>
                                            <input id="lname" type="text" className={`form_control ${lastName && 'blue_border'}`} {...register("lastName", rules.fullName)} autoComplete='off' placeholder='Enter last name' />
                                            {/* <span className="input_fild_remove"><img src={input_fild_remove} alt="fild_remove_icon" /></span> */}
                                            {lastName && <span className='input_fild_remove' onClick={() => setValue('lastName', '')}><img src={input_fild_remove} alt="fild_remove_icon" /></span>}
                                            {errors.lastName?.type === "required" && <span className='show_error_msg'>Please enter last name</span>}
                                            {errors.lastName?.type === "pattern" && <span className='show_error_msg'>Please enter correct last name format!</span>}
                                        </div>

                                    </div>
                                    <div className='d_flex_center per_detais email_requrd'>
                                        <div className="form_group email_fild">
                                            <label>Email</label>
                                            <input id="email" type="email" className={`form_control ${email && 'blue_border'}`} placeholder='e.g. w.jennings@example.com' {...register("email", rules.email)} style={{ pointerEvents: true ? 'none' : 'auto' }} />
                                            {/* {email && <span className='input_fild_remove' onClick={() => setValue('email', '')}><img src={input_fild_remove} alt="fild_remove_icon" /></span>} */}
                                            {errors.email?.type === "required" && <span className='show_error_msg'>Please enter email</span>}
                                            {errors.email?.type === "matchPattern" && <span className='show_error_msg'>Please enter correct email format!</span>}
                                            {/* <span className="input_fild_remove"><img src={input_fild_remove} alt="fild_remove_icon" /></span> */}
                                        </div>
                                        <div className="form_group phone_fild">
                                            <label>Phone</label>
                                            <span className='selected-dial-code'>+1</span>
                                            <Controller
                                                control={control}
                                                {...register("number", {
                                                    required: true,
                                                    validate: { matchPattern: (value: string) => /^[0-9()\-.]+$/.test(value) },
                                                },)}
                                                render={({ field: { onChange } }) => (
                                                    <ReactInputMask id="phone" className={`form_control conform_plus_one ${number && 'blue_border'}`}
                                                        mask={"(999)-999-9999"}
                                                        placeholder={"(___)-___-____"}
                                                        onChange={(e) => onChange(e)}
                                                        value={number}
                                                    // style={{pointerEvents : adminUser ? 'none' : 'auto'}}
                                                    />
                                                )}
                                            />
                                            {number && number !== '(___)-___-____' && <span className='input_fild_remove' onClick={() => setValue('number', '')}><img src={input_fild_remove} alt="fild_remove_icon" /></span>}
                                            {/* <span className='input_fild_remove' onClick={() => setValue('number', '')}><img src={input_fild_remove} alt="fild_remove_icon" /></span> */}
                                            {errors.number?.type === "required" && <span className='show_error_msg'>Please enter phone Number</span>}
                                            {errors.number?.type === "matchPattern" && <span className='show_error_msg'>Please enter valid phone Number</span>}
                                        </div>
                                    </div>
                                </div>}
                            </form>
                        </li>
                        <li>
                            <form className='personal_setting' id='change password' onSubmit={changePasswordhandleSubmit(onSubmitChangePassword)}>
                                <div className='per_info'>
                                    <div className='per_detials'>
                                        <span className='per_pic'><img src={lock_info} alt="personal_pic" /></span>
                                        <span className='per_name'>Change Password</span>
                                    </div>
                                    <div className='per_arrow' onClick={(event) => {
                                        event.preventDefault();
                                        setView('Change Password');
                                    }}>
                                        <span className={`${view === 'Change Password' ? 'set_arrow open' : 'set_arrow close'}`}>
                                            <img src={setting_drop_arrow} alt="setting_drop_arrow" />
                                        </span>
                                    </div>

                                </div>
                                {view === "Change Password" && (
                                    <div className="persnol_details">
                                        <div className="d_flex_center per_detais">
                                            <div className="form_group">
                                                <label>Enter Current Password</label>
                                                <div className={'change-password-input'}>
                                                    <input
                                                        id="password"
                                                        type={statePassword?.isPasswordVisible ? 'password' : 'text'}
                                                        placeholder="*********"
                                                        className={`form_control ${changePasswordIsSubmitted &&
                                                            (changePasswordErrors.currentPassword?.type ===
                                                                "required" ||
                                                                changePasswordErrors.currentPassword?.type ===
                                                                "matchPattern" || changePasswordErrors.currentPassword?.type === 'minLength' ||
                                                                passwordError
                                                                ? "border_red"
                                                                : `${currentPasswordData.isDirty
                                                                    ? "border_green"
                                                                    : ""
                                                                }`)
                                                            }`}
                                                        {...changePasswordRegister("currentPassword", {
                                                            required: "Please enter current password",
                                                            minLength: {
                                                                value: 8,
                                                                message: "Password must be at least 8 characters",
                                                              }
                                                        })}
                                                        {...changePasswordRegister('currentPassword', {
                                                            onChange: (e) => {
                                                                setState({ passwordError: '' })
                                                            },
                                                        })}

                                                    />

                                                </div>
                                                {passwordError !== '' && <span className='show_error_msg'>{passwordError}</span>}
                                                {/* {changePasswordErrors.currentPassword?.type === "required" && <span className='show_error_msg'>Please enter current password</span>} */}
                                                {/* {changePasswordErrors.currentPassword?.type === "matchPattern" && <span className='show_error_msg'>Password must be at least 8 characters.</span>} */}
                                                {currentPassword && (
                                                    <div className='d_flex_center gap_10 input_fild_remove input_remove_password'><span className="eye_pass d_flex visible" onClick={() => setStatePassword({ ...statePassword, isPasswordVisible: !statePassword?.isPasswordVisible })}><img src={!statePassword?.isPasswordVisible ? gridicons_not_visible : gridicons_visible} alt="eye" /></span>
                                                        <span
                                                            className="d_flex"
                                                            onClick={() => {
                                                                setChangePassword("currentPassword", "");
                                                                setState({ passwordError: '' })
                                                                resetField("currentPassword");
                                                            }}
                                                        >
                                                            <img src={input_fild_remove} alt="fild_remove_icon" />
                                                        </span>
                                                    </div>
                                                )}
                                                {changePasswordErrors?.currentPassword && (
                                                    <span className="show_error_msg">
                                                        {changePasswordErrors?.currentPassword?.message}
                                                    </span>
                                                )}
                                            </div>


                                            <div className="form_group">
                                                <label>Enter New Password</label>
                                                <div className={'change-password-input'}>
                                                    <input
                                                        id="newPassword"
                                                        type={statePassword?.isConfirmPasswordVisible ? 'password' : 'text'}
                                                        placeholder="*********"
                                                        className={`form_control ${changePasswordIsSubmitted &&
                                                            (changePasswordErrors.newPassword?.type ===
                                                                "required" ||
                                                                changePasswordErrors.newPassword?.type ===
                                                                "matchPattern" || changePasswordErrors.newPassword?.type === 'minLength'
                                                                ? "border_red"
                                                                : `${newPasswordData.isDirty ? "border_green" : ""
                                                                }`)
                                                            }`}
                                                            {...changePasswordRegister("newPassword", {
                                                                required: "Please enter new password",
                                                                minLength: {
                                                                    value: 8,
                                                                    message: "Password must be at least 8 characters",
                                                                  },
                                                                validate: {
                                                                  matchPattern: (value) =>
                                                                  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+]).{8,}$/.test(value) ||
                                                                    "Password must include at least one capital letter, one special character and one digit.",
                                                                },
                                                              })}
                                                    />
                                                </div>
                                                {newPassword && (
                                                    <div className='d_flex_center gap_10 input_fild_remove input_remove_password'>
                                                        <span className="d_flex eye_pass visible" onClick={() => setStatePassword({ ...statePassword, isConfirmPasswordVisible: !statePassword?.isConfirmPasswordVisible })}><img src={!statePassword?.isConfirmPasswordVisible ? gridicons_not_visible : gridicons_visible} alt="eye" /></span>
                                                        <span
                                                            className="d_flex"
                                                            onClick={() => {
                                                                setChangePassword("newPassword", "");
                                                                resetField("newPassword");
                                                            }}
                                                        >
                                                            <img src={input_fild_remove} alt="fild_remove_icon" />
                                                        </span>
                                                    </div>
                                                )}
                                                {changePasswordErrors?.newPassword && (
                                                    <span className="show_error_msg">
                                                        {changePasswordErrors?.newPassword?.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </li>
                    </ul>
                </div>
                <div className='d_flex_center upload_btn'>
                    {view === 'Personal Details' ?
                        <button className='btn primary_btn blue_btn' type="submit" form={"personal details"}>
                            Save Changes
                        </button> :
                        <button className='btn primary_btn blue_btn' type="submit" form={"change password"}>
                            Save Changes
                        </button>}
                </div>
            </div>
        </div>
    )
}