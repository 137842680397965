import { ReactTransition } from "components/react-trasition-group/ReactTransitionGroup";
import { useEffect, useRef, useState, memo } from "react";

interface MenuContainerProps {
    className?: string;
    data?: any;
    row?: any;
    TitleComponent?: any;
    ChildComponent?: any;
    transitionClass? : string;
    id? :string;
}

const MenuContainer = ({ className, TitleComponent, ChildComponent,transitionClass = "transition",id }: MenuContainerProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        document.addEventListener("click", toggle);
        document.addEventListener("contextmenu", toggle);
        return () => {
            document.removeEventListener("click", toggle);
            document.removeEventListener("contextmenu", toggle);
        };
    }, []);

    function toggle(event: any) {
        if(event.target === inputRef.current || inputRef.current?.contains(event.target)){
            event?.preventDefault()
            setIsOpen(true)
        }else{
            setIsOpen(false)
        }
    }

    // function onTitleClickHandler(event: React.MouseEvent<HTMLButtonElement>) {
    //     event.stopPropagation();
    //     setIsOpen(!isOpen);
    // }

    return (
        <div className={className} id={id}>
            <TitleComponent ref={inputRef} toggle={toggle} onTitleClickHandler={toggle} isOpen={isOpen} />
            <ReactTransition flag={isOpen} timeout={800} classNames={transitionClass}>
                <ChildComponent isOpen={isOpen} setIsOpen={setIsOpen} />
            </ReactTransition>
        </div>
    )
}

export default memo(MenuContainer)